// 保护管理规划
const selfData = {
    snTable: {
        table: {
            data: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'MC',
                label: '保护管理规划名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'LJ',
                label: '附件',
                showFileBtn: true,
            }],
        },
    },
};
export default selfData;